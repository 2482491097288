.customer-form {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 20px 0;
  font-size: 14px;
  border-top: 1px solid #ddd;

  .form-group:last-child {
    margin-bottom: 0;
  }

  .subtitle {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
  }

  input,
  select {
    border-radius: 15px;
    font-size: inherit;
    transition: border 0.5s;
  }

  input.error,
  select.error {
    border: 1px solid #dc3545;
  }

  input.success,
  select.success {
    border: 1px solid green;
  }
}

.customer-detail {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 20px 0;
  font-size: 14px;
  text-align: left;
}

.customer-form:last-child {
  padding-bottom: 0;
}

.read-only-value {
  color: #697490;
}

.customer-modal {
  .spinner-border {
    color: white;
  }

  .modal-header {
    border-bottom: 0;
    font-weight: 500;
  }

  .modal-header .h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-footer {
    border-top: none;
  }

  .credentials {
    width: 100%;
    padding: 20px;
    border: 1px solid #d4e0f3;
    background: #f6faff;
    border-radius: 5px;
  }
}
