@import "../node_modules/react-accessible-accordion/dist/fancy-example.css";
@import "./Custom.scss";

#root {
  height: 100%;
}

.App {
  text-align: center;
  font-size: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-body {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  padding: 2em 30px;
  height: calc(100% - 125px);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  min-width: 1200px;
  color: #333;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@page {
  size: "auto";
  margin: 1cm;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: auto;
  }

  .no-print {
    display: none !important;
  }

  .print-only {
    display: flex !important;
  }

  .print-border {
    border-radius: 5px;
    border: 1px solid #dee2e6 !important;
  }
}

.print-only {
  display: none;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.d-flex-not-important {
  display: flex;
}

.text-gold {
  color: gold;
}

.red-asterisk {
  margin-left: 5px;
  color: #dc3545;
}

.read-only-form {
  background-color: #eff5fd;
}

.sections-container {
  padding: 2em 30px 0 30px;
  height: calc(100% - 55px);
}

.dark-bg-primary {
  background-color: #3572cf;
}

.overflow-hidden {
  overflow: hidden !important;
}

.spinner-border {
  color: #3d7fe5;
  margin: 0 auto;
}

.btn {
  font-weight: 500 !important;
}

.btn-danger .spinner-border {
  color: white;
}

.nowrap {
  flex-wrap: nowrap !important;
}

.inline-flex {
  display: inline-flex;
}

.display-table {
  display: table;
}

.font-weight-medium {
  font-weight: 500;
}

.font-600 {
  font-weight: 500;
}

.font-12 {
  font-size: 12px;
}

.vertical-center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.horizontal-center-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;

  li:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  li {
    &.active a {
      z-index: 1;
      color: white;
      background-color: #3d7fe5;
      border-color: white;
    }

    &.disabled a {
      color: #6c757d;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #dee2e6;
    }

    &:first-child a {
      margin-left: 0;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child a {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    a {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #3d7fe5;
      background-color: #fff;
      border: 1px solid #dee2e6;
      text-decoration: none;
    }
  }
}

.footer {
  max-height: 125px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-end;
  padding: 0 25px 30px;
  border-top: solid 1px #d4e0f38a;

  .faulkner {
    color: #3d7fe5;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    padding-top: 20px;
  }

  .copyright {
    font-size: 12px;
    color: gray;
  }
}

.accordion {
  border: none;
}

.no-data-row {
  border-top: none !important;
  line-height: 34px;
  border-color: #d4e0f38a !important;
  color: #737f99;
}

.accordion__panel {
  padding: 10px;

  .no-data-row {
    border: none !important;
  }
}

.accordion__item {
  border-top: none !important;
  border-bottom: 1px solid white;
  margin: 1px 0;
}

.accordion__button {
  padding: 14px;
  line-height: 16px;

  &:not(.bg-success) {
    background: #3d7fe5 !important;
  }

  color: white;
  font-weight: 500;
  border-radius: 5px;
  outline: none;

  &::before {
    display: none;
  }

  .fa-chevron-up {
    transition: transform 0.5s;
  }

  &[aria-expanded="true"] {
    .fa-chevron-up {
      transform: rotate(180deg);
    }

    .only-expanded {
      display: flex;
    }
  }
}

.border-radius-50 {
  border-radius: 50%;
}

.only-expanded {
  display: none;
}

.more-vertical {
  .fa-ellipsis-v {
    font-size: 20px;
  }

  .dropdown-toggle::after {
    display: none;
  }

  span {
    font-size: 14px;
  }

  float: right;
  line-height: 30px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.square-20 {
  width: 20px;
  height: 20px;
}

.react-bootstrap-table th.sortable {
  cursor: pointer;
}

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #ccc;
}

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #ccc;
}

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

.react-bootstrap-table th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

.react-bootstrap-table th .order-4:after {
  content: "\2193";
  opacity: 0.4;
}

.react-bootstrap-table th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191";
}

.react-bootstrap-table th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:after {
  content: "\2193";
}

.react-bootstrap-table-pagination-list .pagination {
  justify-content: flex-end;
}

.custom-rounded-button-refresh {
  padding: 7px 8px;
}

.badge,
th {
  font-weight: 500 !important;
}

.ag-header-cell {
  font-weight: 500 !important;
}

.ag-cell-value {
  font-weight: 400 !important;
}

.react-bs-table-no-data {
  text-align: center;
}
