.tab-time-employees {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .arrow {
    margin-top: 48px;

    .fas {
      margin-right: 0;
    }
  }
  .employees {
    font-weight: 500;

    .flex-table.header {
      color: #737f99;
    }

    .flex-row {
      line-height: 32px;
      text-indent: 15px;
      min-width: 180px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .row-container {
      z-index: -1;
      background: #eaf2ff;
      border-right: 2px solid #3d7fe5;
    }

    .employee-name {
      margin-left: 2px;
    }
  }
}

.job-detail-time-section {
  background: white;
  border: none;
  font-size: 16px;

  .nav-link {
    color: #333;
    border: none;
  }

  .nav-link.active {
    color: #3d7fe5;
  }
}

.job-detail-time-employees {
  .times {
    .flex-table.row,
    .flex-table.header {
      display: inline-flex;
    }
  }
}
