.navigation {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  background: #eaf2ff;

  .nav {
    height: 55px;
    background: #eaf2ff;
  }

  .nav .nav-link {
    font-weight: 500;
    color: #333;
    height: 55px;
    vertical-align: middle;
    display: table-cell;
    text-transform: capitalize;
  }

  .nav .nav-link.active {
    text-decoration: underline;
    color: #3d7fe5;
  }

  .app-name {
    background: #eaf2ff;
    line-height: 55px;
    font-size: 22px;
    padding-left: 30px;
  }
}
