.job-detail-employees-time-report {
  .time-container {
    margin: 0 5px;
  }
  .employee-row {
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    &:not(:first-child) {
      border-top: 1px solid #d4e0f38a !important;
    }
  }
}
