.job-detail {
  h5 {
    margin: 20px 0;
    text-align: left;
    text-decoration: underline;
  }

  .nav-item {
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 10px 0;

    &:not(:first-child) {
      border-left: 1px solid #dee2e6;
    }

    &.active {
      text-decoration: underline;
    }
  }

  .lighter-color {
    color: #737f99;
  }

  .btn {
    font-size: inherit;
  }

  .job-lead-container {
    margin: 0 0 25px 0;
    margin-left: 92px !important;
    justify-content: flex-start !important;

    span:first-child {
      margin-right: 5px;
    }
  }

  .fas,
  .fa,
  .far {
    margin-right: 8px;
  }

  .fa-pencil-alt {
    margin: 0 15px;
  }

  .row.first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .job-name {
    font-size: 16px;
    line-height: 30px;
    margin: 0 15px;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .job-description-container {
    min-height: 60px;
    border: solid 2px #d4e0f38a;

    .label {
      position: absolute;
      top: -15px;
      padding: 3px 4px;
      font-size: 10px;
      border-radius: 8px;
      background: #e1f8ff;
      border: 1px solid #9ee8ff;
      white-space: nowrap;
      align-self: center;
      font-weight: 500;
    }
  }

  .job-internal-note-container {
    min-height: 60px;
    border: solid 2px #d4e0f38a;

    .label {
      position: absolute;
      top: -15px;
      padding: 3px 4px;
      font-size: 10px;
      border-radius: 8px;
      background: #fdf1db;
      border: 1px solid #f9d89c;
      white-space: nowrap;
      align-self: center;
      font-weight: 500;
    }
  }

  .info-container {
    border-bottom: solid 2px #d4e0f38a;
    justify-content: space-between;
    flex-wrap: nowrap;

    .back-office-notes {
      padding: 0;
      margin-right: 5px;
      min-height: 80px;
      font-size: 12px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
      max-width: 70%;

      .text-container {
        border-radius: 3px;
        padding: 8px;
        background: #eaf2ff;
        height: 100%;
        display: flex;
        justify-content: space-between;

        &:last-child {
          margin-top: 5px;
        }

        span:not(.label) {
          flex-grow: 1;
          align-self: center;
        }

        .label {
          padding: 3px 4px;
          font-size: 10px;
          border-radius: 8px;
          background: #e1f8ff;
          border: 1px solid #9ee8ff;
          white-space: nowrap;
          align-self: center;
          font-weight: 500;
        }
      }
    }

    span.btn {
      color: white;
    }

    .inline-flex {
      border-radius: 5px;
    }

    .other-info {
      flex-wrap: wrap;
      display: flex;
      margin-bottom: 5px;
      margin-left: 5px;
      min-width: 430px;
      padding: 0 10px;

      .location {
        margin-top: 8px;
      }

      .location,
      .distance {
        line-height: 28px;
      }
    }
  }

  .section-container {
    flex-grow: 1;
    margin: 25px 0 25px 25px;
    font-size: 14px;
  }
}

.status-container {
  display: flex;
  align-items: center;

  .basic-select.status {
    min-width: 150px;

    .select__control {
      border-color: #3d7fe5;
      cursor: pointer;
    }

    .select__menu {
      border-color: #3d7fe5;
      cursor: pointer;
    }
  }
}
