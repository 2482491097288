.change-order-component {
  font-weight: 500;

  p {
    color: #737f99;
    font-size: 14px;
    word-break: break-all;
    margin: 0;
  }

  .wo-date {
    color: #333;
  }

  .wo-description-title {
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }

  .extra-info {
    margin-top: 1em;
  }

  .employee-hours {
    color: white;
    flex-direction: row;
    float: left;
    background: transparent;

    .employee-hour-container {
      line-height: 32px;
      border-radius: 8px;
      display: flex;
    }
  }

  .employee-hours:not(:first-child) {
    margin-top: 0.5em;
  }

  .employee-hours-container {
    font-size: 14px;
    margin: 1rem 0;

    .fas {
      margin-right: 8px;
    }

    p {
      span {
        color: #5c6577;
      }
    }
  }

  .wo-creator {
    color: #737f99;
    margin-left: 10px;
  }

  .client-signature {
    max-width: 100%;
  }
}
