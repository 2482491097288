@import "../../node_modules/@fullcalendar/core/main.css";
@import "../../node_modules/@fullcalendar/daygrid/main.css";

.jobs-calendar-container {
  padding: 25px 0 0 0;
  height: calc(100% - 38px);

  .fc-center {
    display: inline-flex;
    padding-left: 45px;

    h2 {
      font-size: 16px;
      line-height: 24px;
      color: #333;
    }
  }

  .fc-right {
    padding-right: 45px;
  }

  .fc-left button {
    border: 1px solid #3d7fe5;
    font-size: inherit;
    color: #3d7fe5;
    background-color: #eaf2ff;
  }

  .fc-left button:hover {
    color: #3d7fe5;
  }

  .fc-right button {
    background: none;
    border: none;
    color: gray;
    padding: 0;
  }

  .fc-right button.fc-button-active {
    background: none;
    border: none;
    color: #3d7fe5;
  }

  .fc-header-toolbar {
    font-size: 12px;
  }

  .fc-prev-button,
  .fc-next-button {
    background: none;
    border-radius: 100%;
    color: #3d7fe5;
    padding: 0px 5px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
    border: none;

    span {
      margin-bottom: 1px;
    }
  }

  .fc-event-container {
    font-weight: 500;
  }

  thead td,
  .fc-day-header {
    border-color: transparent;
    text-align: left;
    text-transform: uppercase;
    color: gray;
    font-size: 10px;
    text-indent: 2px;
    letter-spacing: 1px;
    padding-bottom: 8px;
  }

  .fc-day {
    border-left-color: transparent;
  }

  .fc-day-number {
    float: left;
    margin: 2px 5px;
  }

  td.fc-today {
    background: #eaf2ff;
  }

  .fc-sun .fc-day-number {
    color: #dc3545;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 2em;
    padding-bottom: 0.8em;
    border-bottom: solid 2px #d4e0f3;
  }

  .fc-day-grid-event {
    color: white;
    padding: 10px 5px;
    font-size: 14px;
    margin: 2px 0;
    border-radius: 8px;
    border: none;
    overflow: hidden;

    span {
      padding: 2px 4px;
    }
  }

  .tentative_badge {
    margin-left: 10px;
    background: #0000004f;
    padding: 6px !important;
    border-radius: 8px;
    font-size: 10px;
    line-height: 10px;
  }
}
