.new-job-btn {
  position: fixed;
  right: 24px;
  border-radius: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  margin-top: -35px;
  outline: none;
  z-index: 100;
}

.jobs-search input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.jobs-search .input-group-text {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  background-color: #3d7fe5;
}

.view-btn {
  border-radius: 100%;
  border: none;
  margin: 0 5px;
}

.jobs-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
