.week-calendar .DayPicker-Month {
  border-collapse: separate;
}
.week-calendar .DayPicker-Day {
  padding: 0.5em 0.6em;
}
.week-calendar .DayPicker-WeekNumber {
  outline: none;
}
.week-calendar .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}
.week-calendar .DayPicker-Day--hoverRange {
  background-color: #efefef !important;
}

.week-calendar .DayPicker-Day--selectedRange {
  background-color: #eaf2ff !important;
}

.week-calendar .DayPicker-Day--selectedRangeStart {
  background-color: #3d7fe5 !important;
  border: 1px solid #3d7fe5;
  font-weight: 500;
}

.week-calendar .DayPicker-Day--selectedRangeEnd {
  background-color: #3d7fe5 !important;
  border: 1px solid #3d7fe5;
  font-weight: 500;
}

.week-calendar
  .DayPicker-Day--selectedRange:not(
    .DayPicker-Day--outside
  ).DayPicker-Day--selected,
.week-calendar
  .DayPicker-Day--hoverRange:not(
    .DayPicker-Day--outside
  ).DayPicker-Day--selected {
  color: black !important;
}

.calendar-btn {
  border-radius: 100%;
}

.week-string {
  line-height: 31px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}

.week-string .fa-chevron-down {
  margin: 0 15px;
  font-size: 14px;
}

.date-modal .modal-body,
.date-modal .modal-content {
  display: inline-block;
  width: auto;
}
