.new-customer-btn {
  position: fixed;
  right: 24px;
  border-radius: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  margin-top: 15px;
  outline: none;
  z-index: 100;
}

.customers-title {
  line-height: 35px;
}

.customers-search input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.customers-search .input-group-text {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  background-color: #3d7fe5;
}

.status-select {
  border: none;
  background: none;
  margin: 0 auto;
  font-family: inherit;
  font-weight: 500;
}

.new-customer-btn {
  position: fixed;
  right: 24px;
  border-radius: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  margin-top: 15px;
  outline: none;
}

.error-modal {
  .modal-header {
    border-bottom: none;
    background: #dc35452e;
    color: #9c1926;
    font-weight: 500;
  }
}

.customers-list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .flex-table {
    padding: 8px 0;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .options-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    margin-right: -1px;
    font-size: 16px;
    border-top: solid 1px #d4e0f3;
    border-bottom: solid 0.5px #d4e0f3;
    background: #eaf2ff;
    width: 0px;
    display: table;
    padding: 0.5em 0.5em;
    overflow: hidden;
    transition: width 0.5s;

    span {
      display: table-cell;
      vertical-align: middle;
      padding: 8px 0;
      color: #93b8f2;
      line-height: 29px;
      border-left: 1px solid #d4e0f3;
      cursor: pointer;
    }

    span:hover {
      color: #3d7fe5;
    }

    span:first-child {
      border-left: none;
    }
  }

  .flex-table.header {
    padding: 0;
  }

  .flex-table.row {
    border-bottom: solid 1px #d4e0f38a;
    background: white;
  }

  .flex-row {
    line-height: 28px;
    padding-left: 0;
  }

  .inactive i {
    color: #fbf192;
  }

  .inactive {
    background: #fdfade;
    border: 1px solid #fbf192;
  }

  .active:not(.page-item) i {
    color: #affbd7;
  }

  .active:not(.page-item) {
    background: #e6fdf3;
    border: 1px solid #affbd7;
  }
}

.foreman i {
  color: #9ee8ff;
}

.foreman {
  background: #e1f8ff;
  border: 1px solid #9ee8ff;
}

.executive i {
  color: #feb1fb;
}

.executive {
  background: #fee7fd;
  border: 1px solid #feb1fb;
}

.technician i {
  color: #fbf192;
}

.technician {
  background: #fdfade;
  border: 1px solid #fbf192;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
