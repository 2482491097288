.expenses-reports {
  .tab-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .tab-pane.active {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.expenses-reports .nav-item.active {
  text-decoration: underline;
}

.expenses-reports .select__menu {
  text-align: left;
}

.expenses-reports .select__control {
  border: none !important;
}

.ag-theme-alpine .ag-header {
  background-color: #eaf2ff !important;
  border-bottom: none !important;
}

.ag-row:hover {
  background-color: #eaf2ff !important;
}

.ag-theme-alpine .ag-row,
.ag-theme-alpine .ag-header-row {
  font-family: "Rubik", sans-serif !important;
}

.ag-root-wrapper {
  border: none !important;
}

.date-range-picker {
  font-size: inherit;
}

.date-range-picker
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #4a90e21f !important;
  color: #4a90e2;
}

.date-range-picker .DayPicker-Day {
  border-radius: 0 !important;
}

.date-range-picker .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.date-range-picker .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
