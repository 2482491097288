.employee-modal {
  .spinner-border {
    color: white;
  }

  .modal-header {
    border-bottom: 0;
    font-weight: 500;
  }

  .modal-header .h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-footer {
    border-top: none;
  }

  .credentials {
    width: 100%;
    padding: 20px;
    border: 1px solid #d4e0f3;
    background: #f6faff;
    border-radius: 5px;
  }
}
